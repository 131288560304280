@import "../../variables/variables";

.market-wrapper {
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .market-content {
    width: 1500px;
    position: relative;

    @media screen and (max-width: 1500px) {
      width: 90%;
    }

    .packs {
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 20px;

      .title {
        font-size: 45px;

        @media screen and (max-width: 800px) {
          font-size: 35px;
        }
      }

      .details {
        font-size: 20px;
        padding: 10px;

        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
      }

      .single-pack {
        width: auto;

        @media screen and (max-width: 1024px) {
          max-width: 500px;
        }
      }
    }

    .sold-out {
      font-family: $metal-font;
      font-size: 70px;
      text-align: center;
      color: red;
      padding: 20px;
      opacity: 1;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 100%;

      @media screen and (max-width: 1024px) {
        top: 20%;
      }
    }
  }
}
