@import "../../../variables/variables";

.press {
  text-align: center;
  margin-top: 100px;

  h2 {
    font-family: $metal-font;
    margin-bottom: 30px;
  }

  p {
    font-size: 1.4rem;
    color: $grey-color;
    font-family: $skia-font;
  }

  .press-news {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    #altcoin-wrapper {
      margin-top: 15px;
      a:first-child {
        width: 40%;
      }
    }

    #crypto-wrapper {
      margin-top: 15px;
      a:first-child {
        width: 15%;
      }
    }

    div {
      width: 35%;
      display: flex;
      flex-direction: column;

      a:first-child {
        width: 30%;
        margin-bottom: 20px;
      }
    }

    div:last-child {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .press {
    .press-news {
      display: block;
      div {
        margin: 0 auto;
        width: 80%;
      }
    }
  }
}
