@import "../../variables/variables";

.sign-in, .user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: $metal-font;
  color: white;

  p {
    font-size: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: center;
    padding: 0 20px 20px;
  }

  .MuiButton-label {
    color: red!important;

    @media screen and (max-width: 500px) {
      font-size: 12px!important;
    }
  }

  .MuiButton-outlinedSecondary {
    border: 1px solid rgba(255, 0, 0, 0.5)!important;

    &:hover {
      border: 1px solid red!important;
    }
  }
}

