@import "../../../variables/variables";

.alice-carousel__next-btn {
  text-align: start;
}
#art-carousel .alice-carousel img {
  width: 100% !important ;
}

.packs {
  padding-bottom: 5%;
  margin-top: 150px;
  text-align: center;
  background: black;
  //margin-top: 150px;

  .title {
    font-family: $metal-font;
    color: white;
    font-size: 4rem;
  }

  .details {
    color: $grey-color;
    font-size: 1.4rem;
    font-family: $skia-font;
  }

  .packs-wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1024px) {
  .packs {
    height: auto;
    margin-bottom: 40px;
    margin-top: 0;
    padding-top: 15%;
    .packs-wrapper{
      display: block;
    }
  }
}

//@media screen and (max-height: 680px) and (min-width: 1025px) {
//  .packs {
//    margin-top: 350px;
//  }
//}
