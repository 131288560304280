@import "../../../variables/variables";

.cards-wrapper {
  width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: $metal-font;

  @media screen and (max-width: 1500px) {
    width: 95%;
  }

  h3 {
    font-size: 40px;
  }

  .cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto 20px;

      img {
        width: 250px;
        margin-bottom: 10px;
      }

      p {
        font-size: 20px;
        color: white;
        text-align: center;
      }
    }
  }
}
