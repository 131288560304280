@import "../../../variables/variables";

.authors {
  width: 50%;
  margin: 0 auto;
  border-top: 1px solid $dark-grey;
  padding: 25px;

  @media screen and (max-width: 1200px) {
    width: 95%;
    padding: 25px 0;
  }
}
