@import "../../../variables/variables";

.lands-wrapper {
  width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: $metal-font;

  @media screen and (max-width: 1500px) {
    width: 95%;
  }

  h3 {
    font-size: 40px;
    margin-bottom: 50px;
  }

  .lands {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;

    .land {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto 80px;
      position: relative;

      .print {
        right: 0;
        top: -10%;
        position: absolute;
        color: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../../resourses/Flow_landing_assets/scratch_banner.png");
        padding: 10px 30px;
        font-size: 2.5rem;
        z-index: 5;

        p {
          transform: translate(3px, -7px);
        }
      }

      video {
        width: 350px;
        min-height: 250px;

        @media screen and (max-width: 400px) {
          width: 300px;
          min-height: 200px;
        }
      }

      p {
        font-size: 25px;
        color: white;
        text-align: center;
      }
    }
  }
}
