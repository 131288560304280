@import "../../../variables/variables";

.unpacking {
  margin-top: 100px;

  background-image: url("../../../resourses/Flow_landing_assets/paper_under_text_back.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;

  .unpacking-wrapper {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-evenly;
      .image-wrapper {
        width: 10%;
        margin-right: 100px;

        img {
          width: auto;
        }
      }
      .text-wrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: start;
        font-family: $skia-font;
        font-size: 1.4rem;
        h3 {
          font-family: $metal-font;
          font-weight: normal;
          text-align: center;
          font-size: 3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .unpacking {
    background: none;
    .unpacking-wrapper {
      div {
        display: block;
        text-align: center;
        .image-wrapper {
          margin: 0;
          width: auto;
        }

        .text-wrapper {
          width: 80%;
          margin: 0 auto 40px;
        }
      }
    }
  }
}
