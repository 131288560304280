@import "../../variables/variables";

.visibility-hidden {
  visibility: hidden;
  height: 0;
}

.market-header-wrapper {
  width: 100%;
  border-bottom: 1px solid red;

  .market-header-content {
    width: 1200px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
      width: 95%;
      margin: 0 auto;
    }

    @media screen and (max-width: 800px) {
      height: auto;
      justify-content: center;
      flex-direction: column;
    }

    .market-header-left-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 800px) {
        height: 70px;
      }
    }

    .logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 800px) {
        height: 70px;
      }

      a {
        height: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 800px) {
          height: 50%;
        }
      }

      img {
        height: 70%;
        width: auto;
        padding-right: 50px;

        &:hover {
          cursor: pointer;
        }

        @media screen and (max-width: 800px) {
          height: 90%;
          padding-right: 10px;
        }
      }
    }

    .market-menu {
      font-family: $metal-font;
      color: #d4d4d4;
      font-size: 22px;
      width: max-content;

      @media screen and (max-width: 800px) {
        font-size: 19px;
      }

      a {
        padding-right: 20px;
      }
    }
  }
}
