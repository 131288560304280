@import "../../../variables/variables";

.footer {
  margin-top: 100px;
  font-family: $skia-font;
  font-size: 1.4rem;
  color: $grey-color;
  text-align: center;
  a {
    color: white;
  }

  h2 {
    color: $red-color;
    font-family: $metal-font;
  }

  .logos-wrapper {
    margin-top: 75px;
    display: flex;
    justify-content: space-evenly;
    text-align: center;

    div:nth-child(2) {
      align-self: center;
    }
  }

  .footer-nav {
    margin-bottom: 20px;
    a {
      color: white;
      margin-right: 15px;
    }
    a::after {
      margin-left: 15px;
      content: "|";
    }
    span:last-child {
      a::after {
        content: "";
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    margin-top: 0;
    .footer-nav{
      display: none;
    }
    .authors {
      width: 80%;
    }
  }
}
