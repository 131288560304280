@import "../../variables/variables";

.card-unpacker {
  position: relative;
  display: inline-block;
  max-width: 100%;
  background-color: #000;
  font-family: $metal-font;

  @media screen and (min-width: 320px) and (max-width: 576px) and (orientation: portrait) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    max-width: none;
  }

  img {
    width: auto;
  }

  .background-image {
    width: 100%;
    height: auto;
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
  }

  .my-cards-container {
    position: absolute;
    bottom: 74%;
    left: 78%;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    max-width: 8%;
    cursor: pointer;
    text-align: center;

    .my-cards-image-cover {
      background-image: url("../../resourses/unpacker/images/btn_my_items.png");
      background-size: 100% 100%;
      padding: 10% 5%;

      .my-cards-image {
        max-width: 80%;
        margin: auto;
        border-radius: 3%;
      }
    }
  }

  .my-items-text {
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }

    @media only screen and (max-width: 1100px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 576px) {
      font-size: 10px;
    }
  }

  .coffin-image {
    position: absolute;
    bottom: 32%;
    left: 39%;
    max-width: 20%;
    z-index: 2;
  }

  .lighted-coffin-image {
    bottom: 23%;
    left: 38%;
    max-width: 31%;
  }

  .altar-image {
    position: absolute;
    bottom: 0;
    left: 32%;
    max-width: 33%;
  }

  .bookShelf-image {
    position: absolute;
    bottom: 2%;
    left: 63%;
    max-width: 25%;
  }

  .table-image {
    position: absolute;
    bottom: 2%;
    left: 13%;
    max-width: 13%;
  }

  .dnd-container {
    position: absolute;
    top: 30%;
    left: 0;
    width: 35%;
    height: 70%;
  }

  .bell-image {
    position: absolute;
    bottom: 70%;
    left: 5%;
    max-width: 11%;
    cursor: pointer;
  }

  .bell-image-active {
    bottom: 69%;
    left: 4%;
    max-width: 13%;
    cursor: pointer;
  }

  .list-of-hero-packs {
    position: absolute;
    display: flex;
    bottom: 25%;
    left: 66%;
    z-index: 3;

    .book-container {
      .book-image {
        max-width: 220%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      .book-image--scale {
        // transform: scale(1.05);
      }
    }
  }

  .book-on-table-image {
    position: absolute;
    bottom: 24%;
    left: 14%;
    max-width: 11%;
    cursor: pointer;
  }

  .book-quantity-container {
    position: relative;
    max-width: 40%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: -25%;

    img {
      max-width: 150%;
    }
  }

  .quantity-text {
    position: absolute;
    top: 25%;
    left: 65%;
    color: #fff;
    font-size: 14px;

    @media only screen and (max-width: 1440px) {
      font-size: 10px;
    }

    @media only screen and (max-width: 1100px) {
      font-size: 7px;
    }
  }

  .buy-items-container {
    position: absolute;
    background-image: url("../../resourses/unpacker/images/btn_done_frame.png");
    background-size: 100% 100%;
    padding: 8px 20px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    max-width: 7.2%;

    @media only screen and (max-width: 1440px) {
      padding: 8px 20px;
      font-size: 15px;
    }

    @media only screen and (max-width: 1100px) {
      padding: 6px 10px;
      font-size: 0.75rem;
    }

    @media only screen and (max-width: 576px) {
      padding: 0;
      font-size: 0.5rem;
    }
  }

  .buy-hero-packs {
    bottom: 33%;
    left: 72%;
  }

  .unclaimed-alert {
    position: absolute;
    bottom: 110px;
    left: 75%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: red;

    @media (max-width: 1024px) {
      width: 100%;
      font-size: 15px;
      bottom: 30px;
    }

    a {
      color: white;
    }
  }

  .customHeroModal {
    background-repeat: repeat;
    padding: 20px 0 0 0;
    max-width: 40%;

    @media screen and (max-width: 700px) and (orientation: landscape) {
      padding: 0;
    }

    @media screen and (min-width: 320px) and (max-width: 576px) and (orientation: portrait) {
      padding: 0;
    }
  }
}

.css-79elbk {
  position: initial;

  @media screen and (min-width: 320px) and (max-width: 576px) and (orientation: portrait) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    max-width: none;
  }
}

.css-df17o1 {
  background: rgba(0,0,0,0.5);
  font-family: $metal-font;
  font-size: 25px;
}
