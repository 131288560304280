@import "../../../variables/variables";

.roadmap {
  margin-top: 100px;
  text-align: center;
  font-family: $skia-font;
  font-size: 1.4rem;

  h2 {
    font-family: $metal-font;
  }
  p {
    margin-bottom: 45px;
  }

  //.roadmap-wrapper {
  //  background-image: url("../../resourses/Flow_landing_assets/roadmap_image.png");
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //  background-position: center center;
  //  padding: 600px;
  //}
  .roadmap-container {
    color: white;
    position: relative;
    font-size: 1.1rem;
    height: 120vh !important;
    width: 100%;
    background-image: url("../../../resourses/Flow_landing_assets/roadmap_cat.png");
    background-repeat: no-repeat;
    //background-size: cover;
    background-size: 100% 100%;
    text-align: center;

    .road-wrapper {
      width: 15%;
      height: 15%;
      background-image: url("../../../resourses/Flow_landing_assets/roadmap_box_done.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      padding: 10px 30px;
      span {
        display: block;
      }
      span:first-child {
        font-weight: bold;
      }
    }

    .zero-box {
      top: 78%;
      left: 0%;
    }
    .first-box {
      top: 68%;
      left: 11%;
    }

    .second-box {
      padding-top: 1%;
      top: 59%;
      left: 22%;
    }

    .third-box {
      top: 50%;
      left: 33%
    }

    .fourth-box {
      top: 39%;
      left: 43%;
      background-image: url("../../../resourses/Flow_landing_assets/roadmap_box_todo.png");

    }

    .fifth-box {
      top: 25%;
      left: 54%;
      background-image: url("../../../resourses/Flow_landing_assets/roadmap_box_todo.png");
    }

    .sixth-box {
      top: 13%;
      left: 62%;
      background-image: url("../../../resourses/Flow_landing_assets/roadmap_box_todo.png");
    }

    .seventh-box {
      top: 0%;
      left: 68%;
      background-image: url("../../../resourses/Flow_landing_assets/roadmap_box_todo.png");
    }
  }
}

@media screen and (max-width: 1720px) {
  .road-wrapper {
    width: 16% !important;

    span {
      font-size: 0.8em;
    }
  }
}

@media screen and (max-width: 1290px) {
  .roadmap .roadmap-container .road-wrapper {
    width: 20% !important;
  }
}

@media screen and (max-width: 1024px) {
  .roadmap{
    width: 40%;
    margin: 100px auto;

  .roadmap-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    background-image: none;
    .road-wrapper {
      box-sizing: border-box;
      overflow: hidden;
      background-size: contain;
      padding: 30px 0 20px 0;
      position: relative;
      margin: 10px auto;
      display: block;
      width: 100% !important;
      top: auto;
      left: auto;
      background-position: 50% 50% ;
      span {
        font-size: 1.4rem;
      }
    }
    }
  }
}
@media screen and (max-width: 900px) {
  .roadmap {
    width: 45%;
  }
}

@media screen and (max-width: 800px) {
  .roadmap {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .roadmap {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .roadmap {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .roadmap {
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .roadmap {
    width: 100%;
  }
}
