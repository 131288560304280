@import "../../../variables/variables";

.main-part {
  background-image: url("../../../resourses/Flow_landing_assets/world_map_image_main.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 5%;
}

.start-block {
  #tsparticles {
    //display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
  //
  //background-image: url("../../resourses/Flow_landing_assets/worldmap13.jpg");
  //background-size: cover;
  font-family: $metal-font;
  margin-top: 0px;

  .upper-block {
    height: 150px;

    p {
      font-size: 3rem;
      margin-left: 5rem;
      color: $grey-color;
    }
  }

  .middle-block {
    box-sizing: border-box;
    height: 325px;
    margin-left: 5rem;
    display: flex;
    justify-content: flex-start;
    font-size: 5rem;
    color: $red-color;
    padding-top: 4%;

    h1 {
      text-align: center;
    }

    p {
      margin-left: 7%;
      z-index: 100;
    }

    .logos-wrapper {
      z-index: 100;

      display: flex;
      width: 30%;
      box-sizing: border-box;

      a {
        margin-right: 50px;
        display: block;
      }
    }

    img {
      width: 80%;
    }

    a:first-child {
      align-self: center;

      img {
        width: 100%;
      }
    }
  }

  .bottom-block {
    height: 55vh;
    display: flex;
    justify-content: space-around;

    .packs-header {
      z-index: 100;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: white;
      font-size: 1.4rem;
      margin-left: 2%;

      img {
        width: 100%;
      }

      div:first-child {
        align-self: flex-end;
        width: 25%;

        span {
          text-align: center;
          display: block;
          transform: rotate(342deg) translate(22%, -50%);
        }
      }

      div:nth-child(2) {
        align-self: flex-start;
        width: 30%;

        span {
          text-align: center;
          display: block;
          transform: rotate(351deg) translate(15%, -50%);
        }
      }

      div:last-child {
        align-self: flex-end;
        width: 40%;

        span {
          text-align: center;
          display: block;
          transform: rotate(17deg) translate(-20%, -50%);
        }
      }
    }

    .button-container {
      z-index: 100;

      margin: 5% auto 0;
      width: 25%;

      span,
      p {
        display: block;
        text-align: center;
        font-size: 1.6rem;
        //color: $red-color;
      }
    }

    a {
      display: block;
      font-size: 1.6rem;
      color: white;
    }

    .timer {
      height: auto;
      //margin-bottom: 25%;
      position: relative;
      text-align: center;

      .sale-timer {
        .countdown-container {
          background-size: 90%;
          width: 250px;
        }

        p {
          color: white;
          font-size: 28px;
        }
      }

      > span {
        font-size: 150%;
        background-image: url("../../../resourses/Flow_landing_assets/timer-back.png");
        z-index: 5;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 50px;
      }
    }

    .join-button-wrapper {
      width: 90%;
      margin: 15% auto 0;
    }

    .join-button {
      background-image: url("../../../resourses/Flow_landing_assets/front_page_button.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 10% 10%;
      color: white;
      word-break: break-word;
      text-align: center;
    }
  }

  .vampire-cards {
    z-index: 100;
    width: 30%;
    margin-top: 50px;

    p {
      font-size: 1.2rem;
      width: 80%;
      margin: 0 auto;
      color: white;
      font-family: $skia-font;
    }

    img {
      margin: 0 auto;
      width: auto;
      display: block;
    }
  }
}

@media screen and (max-width: 1400px) {
  .bottom-block .packs-header {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 1366px) {
  .start-block .bottom-block {
    height: 45vh;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) and (max-height: 800px) {
  .start-block .bottom-block {
    height: 56vh;
  }
}

@media screen and (max-width: 1024px) {
  // for all mobiles
  .start-block {
    text-align: center;
    margin-top: 0;
    padding-top: 5%;

    .upper-block {
      height: auto;

      p {
        //margin: 0 0 30px 0;
        font-size: 2rem !important;
        margin: 0 auto 30px;
      }

      div.vertical-center {
        justify-content: center;
      }

      img {
        width: 75%;
      }
    }

    .middle-block {
      padding-top: 0;
      height: auto;
      width: 100%;
      margin: 0;
      flex-direction: column;

      p {
        margin: 0 auto;
      }

      .logos-wrapper {
        width: 60%;
        //display: block;
        a {
          display: block;
          width: 80%;
          margin: 0 auto;
        }

        a:first-child {
          width: 60%;
        }
      }
    }

    .bottom-block {
      margin-top: 100px;
      display: block;
      height: auto;

      .packs-header {
        width: 100%;
        display: flex;
        font-size: 1rem !important;
      }

      .button-container {
        width: 100% !important;
        margin-top: 200px;
        .join-button {
          background-size: contain;
        }

        .timer {
          padding: 0;
          margin: 0;
        }
      }

      .vampire-cards {
        width: 100%;
      }
    }

    .timer {
      height: auto;
      padding: 150px 0;
    }

    .print {
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  // for ipad pro
  .start-block {
    padding-top: 0;

    .logos-wrapper {
      width: 40% !important;
      margin-bottom: 5%;
    }

    .bottom-block {
      .packs-header {
        font-size: 200% !important;
      }

      .button-container {
        //margin-top: 25%;

        span,
        p {
          font-size: 2rem !important;
        }

        .join-button-wrapper {
          margin-top: 0;
        }

        .timer {
          span {
            padding: 60px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .start-block {
    .logos-wrapper {
      margin-bottom: 8% !important;
    }

    .bottom-block {
      margin-top: 22%;
      .packs-header {
        font-size: 110% !important;
      }
      .button-container {
        //margin-top: 30%;
      }
    }
  }
}

@media screen and (max-width: 630px) {
  // for ipad pro
  .start-block {
    .logos-wrapper {
      width: 50% !important;
    }
    .bottom-block {
      margin-top: 15% !important;
      .button-container {
        margin-top: 0%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .packs-header span {
    font-size: 0.8rem !important;
  }

  .join-button-wrapper {
    width: 100% !important;
  }
}
