@import "../../variables/variables";

.queue-data {
  .rodal-dialog {
    width: 550px!important;
    height: 500px!important;
    background-color: #161a1a;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 550px) {
      width: 100%!important;
      height: max-content!important;
    }
  }
}

.queue-data-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $metal-font;
  color: white;
  height: 100%;

  p {
    font-size: 27px;
    padding: 20px;

    span {
      color: red;
    }

    @media screen and (max-width: 580px) {
      font-size: 19px;
    }
  }

  .queue-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    background: white;
    border-radius: 50%;
    color: #161a1a;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
