@import "../../../variables/variables";

.whitelist {
  font-family: $metal-font;
  text-align: center;
  padding-top: 50px;
  margin-top: 100px !important;

  > p:first-child {
    font-size: 3rem;
    margin-bottom: 25px;
  }

  > p:nth-child(2) {
    margin: 0 auto;
    width: 75%;
    font-size: 3rem;
  }

  .info-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .info-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        text-align: start;
        font-family: $skia-font;
        font-size: 1.4rem;
        color: white;
        margin-bottom: 20px;
      }

      div:last-child {
        margin-top: 80px;
      }
      .join-button {
        padding: 20px 50px;
        min-width: 120px;
        min-height: 28px;
        color: white;
        font-size: 1.8rem;
        background-image: url("../../../resourses/Flow_landing_assets/button-decor.png");
        background-size: 100%;
        background-repeat: no-repeat;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .bonus-wrapper {
      width: 50%;
      video {
        width: 80%;
      }
      p {
        font-size: 200%;
        //transform: rotate(-10deg) translate(12%, 0);
      }
    }
    img {
      width: 70%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .whitelist {
    margin-top: 0;
    .info-container {
      text-align: center;
      margin: 0 auto;
      display: block;

      .info-wrapper {
        width: 100%;
      }
    }
    .bonus-wrapper {
      width: 50% !important;
      margin: 10% auto;
      img {
        width: 100% !important;
      }
    }

    .button {
      margin-top: 20px!important;
    }
  }
}

.Toastify__toast-container--bottom-left {
  font-weight: 600;
}
