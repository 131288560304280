@import "../../../variables/variables";

.contacts {
  color: white;
  h3 {
    font-family: $metal-font;
    font-weight: normal;
    font-size: 3rem;
    color: white;
    margin-bottom: 20px;
  }
}

.contact-icons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 50px auto 70px;
  color: white;
  font-family: $metal-font;

  div {
    margin: 5px;

    a {
      color: white;
      display: flex;
      align-items: center;
    }
    img {
      width: auto;
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-icons{
    flex-direction: column;
    justify-content:space-between;

    div{
      width: 100%;
      margin-bottom: 15px;

      img{
        max-width: 100px;

        @media screen and (max-width: 500px) {
          max-width: 70px;
        }
      }
    }
  }
}
