@import "../../../../variables/variables";

.market-pack.single-pack {
  width: 33%;
  display: flex;
  flex-direction: column;
  font-family: $skia-font;
  font-size: 24px;

  > div {
    align-self: center;
    width: 100%;

    video {
      width: 100%;
    }
  }

  div:first-child {
    position: relative;

    .print {
      left: 50%;
      top: -10%;
      position: absolute;
      color: white;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../../resourses/Flow_landing_assets/scratch_banner.png");
      padding: 10px 30px;
      font-size: 2.5rem;
      font-family: $metal-font;
      z-index: 5;
      p {
        transform: translate(3px, -7px);
      }
    }
  }

  .pack-info {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: $skia-font;

    p {
      margin-bottom: 0;
      margin-left: 0;
      padding-bottom: 15px;
    }

    p:first-child {
      font-size: 1.8rem;
      font-family: $metal-font;
    }
  }

  .packs-left {
    padding: 10px;

    span {
      color: red;
    }
  }

  .reserve {
    padding-top: 10px;

    span {
      color: red;
    }
  }

  .price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .single-pack {
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto 50px;

    > div {
      align-self: center;
    }

    .pack-info {
      p, div {
        width: 90%;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
