@import "../../../variables/variables";

.custom-button {
  padding: 0!important;
  width: 250px;
  min-height: 60px;
  color: white;
  font-size: 1.8rem;
  background-image: url("../../../resourses/Flow_landing_assets/button-decor.png");
  background-size: 100%;
  background-repeat: no-repeat;

  .MuiButton-label {
    font-family: $metal-font!important;
    font-size: 22px;
  }

  &:hover {
    cursor: pointer;
    background-image: url("../../../resourses/Flow_landing_assets/button-spec.png") !important;
    background-position: center;
    transition: 0.1s ease-in-out;
    transform: scale(1.1);
  }
}

.custom-button.MuiButton-textSecondary:hover {
  background-color: initial!important;
}

.custom-button.MuiButtonBase-root {
  color: white!important;
}
