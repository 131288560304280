@import "../../../variables/variables";

.countdown-container, .sale-is-live {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: auto;
  background: url(../../../resourses/timer-back2.png) center center no-repeat transparent;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: 80%;
  font-family: $metal-font;
  padding: 1.4rem 2rem 1rem 4.5rem;

  .countdown-days,
  .countdown-dots,
  .countdown-days-text,
  .countdown-hours,
  .countdown-minutes,
  .countdown-seconds {
    z-index: 2;
    font-size: 35px !important;
    color: white !important;
    text-shadow: 0 0 4px black;
  }

  .countdown-days-text,
  .countdown-hours,
  .countdown-minutes,
  .countdown-seconds {
    margin: 0 0.3em;
    &.dots-space {
      margin-left: 0;
    }
  }
}

.sale-is-live {
  font-size: 30px;
  width: 400px;

  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 20px;
  }
}

.sale-timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  p {
    font-size: 30px;
    padding-bottom: 10px;
  }
}
