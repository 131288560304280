@import "../../../variables/variables";

.videos {
  margin-top: 100px;
  text-align: center;
  font-family: $metal-font;

  p {
    margin-top: 40px;
    font-family: $skia-font;
    font-size: 1.4rem;
    color: $grey-color;
  }

  .main-video {
    height: 60vh;
    width: 60%;
    margin: 30px auto;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .videos-wrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    height: 20vh;
    margin: 40px auto;


    iframe {
      border: none;
      height: 100%;
      width: 33%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .videos {
    height: auto;
    margin-top: 0;

    .main-video{
      width: 100%;
      height: 40vh;
    }

    .videos-wrapper{
      display: block;
      height: auto;
      iframe{
        width: 100%;
      }
    }
  }
}
