@import "../../../variables/variables";

.market-footer {
  font-family: $skia-font;
  text-align: center;
  width: 1500px;
  font-size: 1.4rem;

  @media screen and (max-width: 1500px) {
    width: 90%;
  }
}
