@import "../../variables/variables";

.purchase-congratulation {
  .rodal-dialog {
    width: 580px!important;
    height: 400px!important;
    background-color: #161a1a;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 580px) {
      width: 100%!important;
      height: max-content!important;
    }
  }
}

.purchase-congratulation-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $metal-font;
  color: white;
  height: 100%;

  p {
    font-size: 29px;
    padding: 20px;

    @media screen and (max-width: 580px) {
      font-size: 19px;
    }
  }

  a {
    &:hover {
      color: white!important;
    }
  }
}
