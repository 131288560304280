body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

.btn--spec-filled span {
  font-family: "Metal Mania", serif!important;
  font-weight: normal!important;
}

.btn--spec-filled:hover::after {
  background: none;
}

.btn--spec-filled:hover {
  text-decoration: none !important;
}

.btn--spec-filled {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  min-width: 18.5rem!important;
  min-height: 4.8rem!important;

  background: url(./resourses/btn-decor.png) center center no-repeat transparent;

  background-size: 100%;

}
.btn--spec-filled:hover {
  background-image: url(./resourses/btn_decor_hove_home.png);
}

.btn--spec-filled span {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 1.575rem;
  z-index: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
