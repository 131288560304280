.count-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .button-count {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-width: 55px !important;

    .button-text {
      color: #ff0000 !important;
      font-size: 2rem !important;
    }
  }

  .count-text {
    color: #fff !important;
    font-size: 2rem !important;
  }
}
