@import "../../variables/variables";

.my-items-wrapper {
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .my-items-content {
    width: 1500px;

    @media screen and (max-width: 1500px) {
      width: 90%;
    }

    .title {
      font-size: 40px;
      font-family: $metal-font;
      color: white;
      text-align: center;
      padding: 20px 20px 0;
    }

    .packs {
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 50px;

      .packs-wrapper {
        margin-top: 50px;
      }

      .single-pack {
        width: auto;

        @media screen and (max-width: 1024px) {
          max-width: 500px;
        }
      }
    }
  }
}
