@import "../../../variables/variables";

.purchase-wrapper {
  padding: 0 20px 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $metal-font;

  @media screen and (max-width: 600px) {
    padding: 0;
  }

  .purchase-total-container {
    font-family: $metal-font;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
      font-size: 25px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .processing {
    width: 105px;
    color: $dark-grey;
    margin-top: 10px;
  }

  .processing:after {
    overflow: hidden;
    display: inline;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 1.5s infinite;
    animation: ellipsis steps(4,end) 1.5s infinite;
    content: "\2026";
    width: 0;
  }
}
